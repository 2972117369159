export const AreaCode = [
  // {
  //     "value": "",
  //     "text": "区号",
  // },
  {
    "en": "China",
    "zh": "中国",
    "value": "+86",
    "text": "+86（中国）",
  },
  {
    "en": "Angola",
    "zh": "安哥拉",
    "value": "+0244",
    "text": "+0244（安哥拉）",
  },
  {
    "en": "Afghanistan",
    "zh": "阿富汗",
    "value": "+93",
    "text": "+93（阿富汗）",
  },
  {
    "en": "Albania",
    "zh": "阿尔巴尼亚",
    "value": "+335",
    "text": "+335（阿尔巴尼亚）",
  },
  {
    "en": "Algeria",
    "zh": "阿尔及利亚",
    "value": "+213",
    "text": "+213（阿尔及利亚）",
  },
  {
    "en": "Andorra",
    "zh": "安道尔共和国",
    "value": "+376",
    "text": "+376（安道尔共和国）",
  },
  {
    "en": "Anguilla",
    "zh": "安圭拉岛",
    "value": "+1254",
    "text": "+1254（安圭拉岛）",
  },
  {
    "en": "Antigua and Barbuda",
    "zh": "安提瓜和巴布达",
    "value": "+1268",
    "text": "+1268（安提瓜和巴布达）",
  },
  {
    "en": "Argentina",
    "zh": "阿根廷",
    "value": "+54",
    "text": "+54（阿根廷）",
  },
  {
    "en": "Armenia",
    "zh": "亚美尼亚",
    "value": "+374",
    "text": "+374（亚美尼亚）",
  },
  {
    "en": "Ascension",
    "zh": "阿森松",
    "value": "+247",
    "text": "+247（阿森松）",
  },
  {
    "en": "Australia",
    "zh": "澳大利亚",
    "value": "+61",
    "text": "+61（澳大利亚）",
  },
  {
    "en": "Austria",
    "zh": "奥地利",
    "value": "+43",
    "text": "+43（奥地利）",
  },
  {
    "en": "Azerbaijan",
    "zh": "阿塞拜疆",
    "value": "+994",
    "text": "+994（阿塞拜疆）",
  },
  {
    "en": "Bahamas",
    "zh": "巴哈马",
    "value": "+1242",
    "text": "+1242（巴哈马）",
  },
  {
    "en": "Bahrain",
    "zh": "巴林",
    "value": "+973",
    "text": "+973（巴林）",
  },
  {
    "en": "Bangladesh",
    "zh": "孟加拉国",
    "value": "+880",
    "text": "+880（孟加拉国）",
  },
  {
    "en": "Barbados",
    "zh": "巴巴多斯",
    "value": "+1246",
    "text": "+1246（巴巴多斯）",
  },
  {
    "en": "Belarus",
    "zh": "白俄罗斯",
    "value": "+375",
    "text": "+375（白俄罗斯）",
  },
  {
    "en": "Belgium",
    "zh": "比利时",
    "value": "+32",
    "text": "+32（比利时）",
  },
  {
    "en": "Belize",
    "zh": "伯利兹",
    "value": "+501",
    "text": "+501（伯利兹）",
  },
  {
    "en": "Benin",
    "zh": "贝宁",
    "value": "+229",
    "text": "+229（贝宁）",
  },
  {
    "en": "Bermuda Is",
    "zh": "百慕大群岛",
    "value": "+1441",
    "text": "+1441（百慕大群岛）",
  },
  {
    "en": "Bolivia",
    "zh": "玻利维亚",
    "value": "+591",
    "text": "+591（玻利维亚）",
  },
  {
    "en": "Botswana",
    "zh": "博茨瓦纳",
    "value": "+267",
    "text": "+267（博茨瓦纳）",
  },
  {
    "en": "Brazil",
    "zh": "巴西",
    "value": "+55",
    "text": "+55（巴西）",
  },
  {
    "en": "Brunei",
    "zh": "文莱",
    "value": "+673",
    "text": "+673（文莱）",
  },
  {
    "en": "Bulgaria",
    "zh": "保加利亚",
    "value": "+359",
    "text": "+359（保加利亚）",
  },
  {
    "en": "Burkina Faso",
    "zh": "布基纳法索",
    "value": "+226",
    "text": "+226（布基纳法索）",
  },
  {
    "en": "Burma",
    "zh": "缅甸",
    "value": "+95",
    "text": "+95（缅甸）",
  },
  {
    "en": "Burundi",
    "zh": "布隆迪",
    "value": "+257",
    "text": "+257（布隆迪）",
  },
  {
    "en": "Cameroon",
    "zh": "喀麦隆",
    "value": "+237",
    "text": "+237（喀麦隆）",
  },
  {
    "en": "Cayman Is",
    "zh": "开曼群岛",
    "value": "+1345",
    "text": "+1345（开曼群岛）",
  },
  {
    "en": "Central African Republic",
    "zh": "中非共和国",
    "value": "+236",
    "text": "+236（中非共和国）",
  },
  {
    "en": "Chad",
    "zh": "乍得",
    "value": "+235",
    "text": "+235（乍得）",
  },
  {
    "en": "Chile",
    "zh": "智利",
    "value": "+56",
    "text": "+56（智利）",
  },
  {
    "en": "Colombia",
    "zh": "哥伦比亚",
    "value": "+57",
    "text": "+57（哥伦比亚）",
  },
  {
    "en": "Congo",
    "zh": "刚果",
    "value": "+242",
    "text": "+242（刚果）",
  },
  {
    "en": "Cook Is",
    "zh": "库克群岛",
    "value": "+682",
    "text": "+682（库克群岛）",
  },
  {
    "en": "Costa Rica",
    "zh": "哥斯达黎加",
    "value": "+506",
    "text": "+506（哥斯达黎加）",
  },
  {
    "en": "Cuba",
    "zh": "古巴",
    "value": "+53",
    "text": "+53（古巴）",
  },
  {
    "en": "Cyprus",
    "zh": "塞浦路斯",
    "value": "+357",
    "text": "+357（塞浦路斯）",
  },
  {
    "en": "Czech Republic",
    "zh": "捷克",
    "value": "+420",
    "text": "+420（捷克）",
  },
  {
    "en": "Denmark",
    "zh": "丹麦",
    "value": "+45",
    "text": "+45（丹麦）",
  },
  {
    "en": "Djibouti",
    "zh": "吉布提",
    "value": "+253",
    "text": "+253（吉布提）",
  },
  {
    "en": "Dominica Rep",
    "zh": "多米尼加共和国",
    "value": "+1890",
    "text": "+1890（多米尼加共和国）",
  },
  {
    "en": "Ecuador",
    "zh": "厄瓜多尔",
    "value": "+593",
    "text": "+593（厄瓜多尔）",
  },
  {
    "en": "Egypt",
    "zh": "埃及",
    "value": "+20",
    "text": "+20（埃及）",
  },
  {
    "en": "EI Salvador",
    "zh": "萨尔瓦多",
    "value": "+503",
    "text": "+503（萨尔瓦多）",
  },
  {
    "en": "Estonia",
    "zh": "爱沙尼亚",
    "value": "+372",
    "text": "+372（爱沙尼亚）",
  },
  {
    "en": "Ethiopia",
    "zh": "埃塞俄比亚",
    "value": "+251",
    "text": "+251（埃塞俄比亚）",
  },
  {
    "en": "Fiji",
    "zh": "斐济",
    "value": "+679",
    "text": "+679（斐济）",
  },
  {
    "en": "Finland",
    "zh": "芬兰",
    "value": "+358",
    "text": "+358（芬兰）",
  },
  {
    "en": "France",
    "zh": "法国",
    "value": "+33",
    "text": "+33（法国）",
  },
  {
    "en": "French Guiana",
    "zh": "法属圭亚那",
    "value": "+594",
    "text": "+594（法属圭亚那）",
  },
  {
    "en": "French Polynesia",
    "zh": "法属玻利尼西亚",
    "value": "+689",
    "text": "+689（法属玻利尼西亚）",
  },
  {
    "en": "Gabon",
    "zh": "加蓬",
    "value": "+241",
    "text": "+241（加蓬）",
  },
  {
    "en": "Gambia",
    "zh": "冈比亚",
    "value": "+220",
    "text": "+220（冈比亚）",
  },
  {
    "en": "Georgia",
    "zh": "格鲁吉亚",
    "value": "+995",
    "text": "+995（格鲁吉亚）",
  },
  {
    "en": "Germany",
    "zh": "德国",
    "value": "+49",
    "text": "+49（德国）",
  },
  {
    "en": "Ghana",
    "zh": "加纳",
    "value": "+233",
    "text": "+233（加纳）",
  },
  {
    "en": "Gibraltar",
    "zh": "直布罗陀",
    "value": "+350",
    "text": "+350（直布罗陀）",
  },
  {
    "en": "Greece",
    "zh": "希腊",
    "value": "+30",
    "text": "+30（希腊）",
  },
  {
    "en": "Grenada",
    "zh": "格林纳达",
    "value": "+1809",
    "text": "+1809（格林纳达）",
  },
  {
    "en": "Guam",
    "zh": "关岛",
    "value": "+1671",
    "text": "+1671（关岛）",
  },
  {
    "en": "Guatemala",
    "zh": "危地马拉",
    "value": "+502",
    "text": "+502（危地马拉）",
  },
  {
    "en": "Guinea",
    "zh": "几内亚",
    "value": "+224",
    "text": "+224（几内亚）",
  },
  {
    "en": "Guyana",
    "zh": "圭亚那",
    "value": "+592",
    "text": "+592（圭亚那）",
  },
  {
    "en": "Haiti",
    "zh": "海地",
    "value": "+509",
    "text": "+509（海地）",
  },
  {
    "en": "Honduras",
    "zh": "洪都拉斯",
    "value": "+504",
    "text": "+504（洪都拉斯）",
  },
  {
    "en": "Hongkong",
    "zh": "香港",
    "value": "+852",
    "text": "+852（香港）",
  },
  {
    "en": "Hungary",
    "zh": "匈牙利",
    "value": "+36",
    "text": "+36（匈牙利）",
  },
  {
    "en": "Iceland",
    "zh": "冰岛",
    "value": "+354",
    "text": "+354（冰岛）",
  },
  {
    "en": "India",
    "zh": "印度",
    "value": "+91",
    "text": "+91（印度）",
  },
  {
    "en": "Indonesia",
    "zh": "印度尼西亚",
    "value": "+62",
    "text": "+62（印度尼西亚）",
  },
  {
    "en": "Iran",
    "zh": "伊朗",
    "value": "+98",
    "text": "+98（伊朗）",
  },
  {
    "en": "Iraq",
    "zh": "伊拉克",
    "value": "+964",
    "text": "+964（伊拉克）",
  },
  {
    "en": "Ireland",
    "zh": "爱尔兰",
    "value": "+353",
    "text": "+353（爱尔兰）",
  },
  {
    "en": "Israel",
    "zh": "以色列",
    "value": "+972",
    "text": "+972（以色列）",
  },
  {
    "en": "Italy",
    "zh": "意大利",
    "value": "+39",
    "text": "+39（意大利）",
  },
  {
    "en": "Ivory Coast",
    "zh": "科特迪瓦",
    "value": "+225",
    "text": "+225（科特迪瓦）",
  },
  {
    "en": "Jamaica",
    "zh": "牙买加",
    "value": "+1876",
    "text": "+1876（牙买加）",
  },
  {
    "en": "Japan",
    "zh": "日本",
    "value": "+81",
    "text": "+81（日本）",
  },
  {
    "en": "Jordan",
    "zh": "约旦",
    "value": "+962",
    "text": "+962（约旦）",
  },
  {
    "en": "Kampuchea (Cambodia )",
    "zh": "柬埔寨",
    "value": "+855",
    "text": "+855（柬埔寨）",
  },
  {
    "en": "Kazakstan",
    "zh": "哈萨克斯坦",
    "value": "+327",
    "text": "+327（哈萨克斯坦）",
  },
  {
    "en": "Kenya",
    "zh": "肯尼亚",
    "value": "+254",
    "text": "+254（肯尼亚）",
  },
  {
    "en": "Korea",
    "zh": "韩国",
    "value": "+82",
    "text": "+82（韩国）",
  },
  {
    "en": "Kuwait",
    "zh": "科威特",
    "value": "+965",
    "text": "+965（科威特）",
  },
  {
    "en": "Kyrgyzstan",
    "zh": "吉尔吉斯坦",
    "value": "+331",
    "text": "+33（吉尔吉斯坦）1",
  },
  {
    "en": "Laos",
    "zh": "老挝",
    "value": "+856",
    "text": "+856（老挝）",
  },
  {
    "en": "Latvia",
    "zh": "拉脱维亚",
    "value": "+371",
    "text": "+371（拉脱维亚）",
  },
  {
    "en": "Lebanon",
    "zh": "黎巴嫩",
    "value": "+961",
    "text": "+961（黎巴嫩）",
  },
  {
    "en": "Lesotho",
    "zh": "莱索托",
    "value": "+266",
    "text": "+266（莱索托）",
  },
  {
    "en": "Liberia",
    "zh": "利比里亚",
    "value": "+231",
    "text": "+231（利比里亚）",
  },
  {
    "en": "Libya",
    "zh": "利比亚",
    "value": "+218",
    "text": "+218（利比亚）",
  },
  {
    "en": "Lithuania",
    "zh": "立陶宛",
    "value": "+370",
    "text": "+370（立陶宛）",
  },
  {
    "en": "Luxembourg",
    "zh": "卢森堡",
    "value": "+352",
    "text": "+352（卢森堡）",
  },
  {
    "en": "Macao",
    "zh": "澳门",
    "value": "+853",
    "text": "+853（澳门）",
  },
  {
    "en": "Madagascar",
    "zh": "马达加斯加",
    "value": "+261",
    "text": "+261（马达加斯加）",
  },
  {
    "en": "Malawi",
    "zh": "马拉维",
    "value": "+265",
    "text": "+265（马拉维）",
  },
  {
    "en": "Malaysia",
    "zh": "马来西亚",
    "value": "+60",
    "text": "+60（马来西亚）",
  },
  {
    "en": "Maldives",
    "zh": "马尔代夫",
    "value": "+960",
    "text": "+960（马尔代夫）",
  },
  {
    "en": "Mali",
    "zh": "马里",
    "value": "+223",
    "text": "+223（马里）",
  },
  {
    "en": "Malta",
    "zh": "马耳他",
    "value": "+356",
    "text": "+356（马耳他）",
  },
  {
    "en": "Mariana Is",
    "zh": "马里亚那群岛",
    "value": "+1670",
    "text": "+1670（马里亚那群岛）",
  },
  {
    "en": "Martinique",
    "zh": "马提尼克",
    "value": "+596",
    "text": "+596（马提尼克）",
  },
  {
    "en": "Mauritius",
    "zh": "毛里求斯",
    "value": "+230",
    "text": "+230（毛里求斯）",
  },
  {
    "en": "Mexico",
    "zh": "墨西哥",
    "value": "+52",
    "text": "+52（墨西哥）",
  },
  {
    "en": "Moldova",
    "zh": "摩尔多瓦",
    "value": "+373",
    "text": "+373（摩尔多瓦）",
  },
  {
    "en": "Monaco",
    "zh": "摩纳哥",
    "value": "+377",
    "text": "+377（摩纳哥）",
  },
  {
    "en": "Mongolia",
    "zh": "蒙古",
    "value": "+976",
    "text": "+976（蒙古）",
  },
  {
    "en": "Montserrat Is",
    "zh": "蒙特塞拉特岛",
    "value": "+1664",
    "text": "+1664（蒙特塞拉特岛）",
  },
  {
    "en": "Morocco",
    "zh": "摩洛哥",
    "value": "+212",
    "text": "+212（摩洛哥）",
  },
  {
    "en": "Mozambique",
    "zh": "莫桑比克",
    "value": "+258",
    "text": "+258（莫桑比克）",
  },
  {
    "en": "Namibia",
    "zh": "纳米比亚",
    "value": "+264",
    "text": "+264（纳米比亚）",
  },
  {
    "en": "Nauru",
    "zh": "瑙鲁",
    "value": "+674",
    "text": "+674（瑙鲁）",
  },
  {
    "en": "Nepal",
    "zh": "尼泊尔",
    "value": "+977",
    "text": "+977（尼泊尔）",
  },
  {
    "en": "Netheriands Antilles",
    "zh": "荷属安的列斯",
    "value": "+599",
    "text": "+599（荷属安的列斯）",
  },
  {
    "en": "Netherlands",
    "zh": "荷兰",
    "value": "+31",
    "text": "+31（荷兰）",
  },
  {
    "en": "New Zealand",
    "zh": "新西兰",
    "value": "+64",
    "text": "+64（新西兰）",
  },
  {
    "en": "Nicaragua",
    "zh": "尼加拉瓜",
    "value": "+505",
    "text": "+505（尼加拉瓜）",
  },
  {
    "en": "Niger",
    "zh": "尼日尔",
    "value": "+227",
    "text": "+227（尼日尔）",
  },
  {
    "en": "Nigeria",
    "zh": "尼日利亚",
    "value": "+234",
    "text": "+234（尼日利亚）",
  },
  {
    "en": "North Korea",
    "zh": "朝鲜",
    "value": "+850",
    "text": "+850（朝鲜）",
  },
  {
    "en": "Norway",
    "zh": "挪威",
    "value": "+47",
    "text": "+47（挪威）",
  },
  {
    "en": "Oman",
    "zh": "阿曼",
    "value": "+968",
    "text": "+968（阿曼）",
  },
  {
    "en": "Pakistan",
    "zh": "巴基斯坦",
    "value": "+92",
    "text": "+92（巴基斯坦）",
  },
  {
    "en": "Panama",
    "zh": "巴拿马",
    "value": "+507",
    "text": "+507（巴拿马）",
  },
  {
    "en": "Papua New Cuinea",
    "zh": "巴布亚新几内亚",
    "value": "+675",
    "text": "+675（巴布亚新几内亚）",
  },
  {
    "en": "Paraguay",
    "zh": "巴拉圭",
    "value": "+595",
    "text": "+595（巴拉圭）",
  },
  {
    "en": "Peru",
    "zh": "秘鲁",
    "value": "+51",
    "text": "+51（秘鲁）",
  },
  {
    "en": "Philippines",
    "zh": "菲律宾",
    "value": "+63",
    "text": "+63（菲律宾）",
  },
  {
    "en": "Poland",
    "zh": "波兰",
    "value": "+48",
    "text": "+48（波兰）",
  },
  {
    "en": "Portugal",
    "zh": "葡萄牙",
    "value": "+351",
    "text": "+351（葡萄牙）",
  },
  {
    "en": "Puerto Rico",
    "zh": "波多黎各",
    "value": "+1787",
    "text": "+1787（波多黎各）",
  },
  {
    "en": "Qatar",
    "zh": "卡塔尔",
    "value": "+974",
    "text": "+974（卡塔尔）",
  },
  {
    "en": "Reunion",
    "zh": "留尼旺",
    "value": "+262",
    "text": "+262（留尼旺）",
  },
  {
    "en": "Romania",
    "zh": "罗马尼亚",
    "value": "+40",
    "text": "+40（罗马尼亚）",
  },
  {
    "en": "Russia",
    "zh": "俄罗斯",
    "value": "+7",
    "text": "+7（俄罗斯）",
  },
  {
    "en": "Saint Lueia",
    "zh": "圣卢西亚",
    "value": "+1758",
    "text": "+1758（圣卢西亚）",
  },
  {
    "en": "Saint Vincent",
    "zh": "圣文森特岛",
    "value": "+1784",
    "text": "+1784（圣文森特岛）",
  },
  {
    "en": "Samoa Eastern",
    "zh": "东萨摩亚(美)",
    "value": "+684",
    "text": "+684（东萨摩亚(美)）",
  },
  {
    "en": "Samoa Western",
    "zh": "西萨摩亚",
    "value": "+685",
    "text": "+685（西萨摩亚）",
  },
  {
    "en": "San Marino",
    "zh": "圣马力诺",
    "value": "+378",
    "text": "+378（圣马力诺）",
  },
  {
    "en": "Sao Tome and Principe",
    "zh": "圣多美和普林西比",
    "value": "+239",
    "text": "+239（圣多美和普林西比）",
  },
  {
    "en": "Saudi Arabia",
    "zh": "沙特阿拉伯",
    "value": "+966",
    "text": "+966（沙特阿拉伯）",
  },
  {
    "en": "Senegal",
    "zh": "塞内加尔",
    "value": "+221",
    "text": "+221（塞内加尔）",
  },
  {
    "en": "Seychelles",
    "zh": "塞舌尔",
    "value": "+248",
    "text": "+248（塞舌尔）",
  },
  {
    "en": "Sierra Leone",
    "zh": "塞拉利昂",
    "value": "+232",
    "text": "+232（塞舌尔）",
  },
  {
    "en": "Singapore",
    "zh": "新加坡",
    "value": "+65",
    "text": "+65（新加坡）",
  },
  {
    "en": "Slovakia",
    "zh": "斯洛伐克",
    "value": "+421",
    "text": "+421（斯洛伐克）",
  },
  {
    "en": "Slovenia",
    "zh": "斯洛文尼亚",
    "value": "+386",
    "text": "+386（斯洛文尼亚）",
  },
  {
    "en": "Solomon Is",
    "zh": "所罗门群岛",
    "value": "+677",
    "text": "+677（所罗门群岛）",
  },
  {
    "en": "Somali",
    "zh": "索马里",
    "value": "+252",
    "text": "+252（索马里）",
  },
  {
    "en": "South Africa",
    "zh": "南非",
    "value": "+27",
    "text": "+27（南非）",
  },
  {
    "en": "Spain",
    "zh": "西班牙",
    "value": "+34",
    "text": "+34（西班牙）",
  },
  {
    "en": "SriLanka",
    "zh": "斯里兰卡",
    "value": "+94",
    "text": "+94（斯里兰卡）",
  },
  {
    "en": "Sudan",
    "zh": "苏丹",
    "value": "+249",
    "text": "+249（苏丹）",
  },
  {
    "en": "Suriname",
    "zh": "苏里南",
    "value": "+597",
    "text": "+597（苏里南）",
  },
  {
    "en": "Swaziland",
    "zh": "斯威士兰",
    "value": "+268",
    "text": "+268（斯威士兰）",
  },
  {
    "en": "Sweden",
    "zh": "瑞典",
    "value": "+46",
    "text": "+46（瑞典）",
  },
  {
    "en": "Switzerland",
    "zh": "瑞士",
    "value": "+41",
    "text": "+41（瑞士）",
  },
  {
    "en": "Syria",
    "zh": "叙利亚",
    "value": "+963",
    "text": "+963（叙利亚）",
  },
  {
    "en": "Taiwan",
    "zh": "台湾省",
    "value": "+886",
    "text": "+886（台湾省）",
  },
  {
    "en": "Tajikstan",
    "zh": "塔吉克斯坦",
    "value": "+992",
    "text": "+992（塔吉克斯坦）",
  },
  {
    "en": "Tanzania",
    "zh": "坦桑尼亚",
    "value": "+255",
    "text": "+255（坦桑尼亚）",
  },
  {
    "en": "Thailand",
    "zh": "泰国",
    "value": "+66",
    "text": "+66（泰国）",
  },
  {
    "en": "Togo",
    "zh": "多哥",
    "value": "+228",
    "text": "+228（多哥）",
  },
  {
    "en": "Tonga",
    "zh": "汤加",
    "value": "+676",
    "text": "+676（汤加）",
  },
  {
    "en": "Trinidad and Tobago",
    "zh": "特立尼达和多巴哥",
    "value": "+1868",
    "text": "+1868（特立尼达和多巴哥）",
  },
  {
    "en": "Tunisia",
    "zh": "突尼斯",
    "value": "+216",
    "text": "+216（突尼斯）",
  },
  {
    "en": "Turkey",
    "zh": "土耳其",
    "value": "+90",
    "text": "+90（土耳其）",
  },
  {
    "en": "Turkmenistan",
    "zh": "土库曼斯坦",
    "value": "+993",
    "text": "+993（土库曼斯坦）",
  },
  {
    "en": "Uganda",
    "zh": "乌干达",
    "value": "+256",
    "text": "+256（乌干达）",
  },
  {
    "en": "Ukraine",
    "zh": "乌克兰",
    "value": "+380",
    "text": "+380（乌克兰）",
  },
  {
    "en": "United Arab Emirates",
    "zh": "阿拉伯联合酋长国",
    "value": "+971",
    "text": "+971（阿拉伯联合酋长国）",
  },
  {
    "en": "United Kiongdom",
    "zh": "英国",
    "value": "+44",
    "text": "+44（英国）",
  },
  {
    "en": "United States of America",
    "zh": "美国/加拿大",
    "value": "+1",
    "text": "+1（美国/加拿大）",
  },
  {
    "en": "Uruguay",
    "zh": "乌拉圭",
    "value": "+598",
    "text": "+598（乌拉圭）",
  },
  {
    "en": "Uzbekistan",
    "zh": "乌兹别克斯坦",
    "value": "+998",
    "text": "+998（乌兹别克斯坦）",
  },
  {
    "en": "Venezuela",
    "zh": "委内瑞拉",
    "value": "+58",
    "text": "+58（委内瑞拉）",
  },
  {
    "en": "Vietnam",
    "zh": "越南",
    "value": "+84",
    "text": "+84（越南）",
  },
  {
    "en": "Yemen",
    "zh": "也门",
    "value": "+967",
    "text": "+967（也门）",
  },
  {
    "en": "Yugoslavia",
    "zh": "南斯拉夫",
    "value": "+381",
    "text": "+381（南斯拉夫）",
  },
  {
    "en": "Zimbabwe",
    "zh": "津巴布韦",
    "value": "+263",
    "text": "+263（津巴布韦）",
  },
  {
    "en": "Zaire",
    "zh": "扎伊尔",
    "value": "+243",
    "text": "+243（扎伊尔）",
  },
  {
    "en": "Zambia",
    "zh": "赞比亚",
    "value": "+260",
    "text": "+260（赞比亚）",
  }
]